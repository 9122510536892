export default {
    light: {
        darkPrimaryColor: '#FFA000',
        lightPrimaryColor: '#FFECB3',
        primaryColor: '#FFC107',
        primaryText: '#212121',
        accentColor: '#009688',
        secondaryText: '#757575',
        dividerColor: '#BDBDBD',
        primaryBackground: '#FFFFFF',
        secondaryBackground: '#FFFFFF',
        lightText: '#FFFFFF'
    },
    dark: {
        darkPrimaryColor: '#455A64',
        lightPrimaryColor: '#CFD8DC',
        primaryColor: '#607D8B',
        primaryText: '#FFFFFF',
        accentColor: '#9E9E9E',
        secondaryText: '#757575',
        dividerColor: '#BDBDBD',
        primaryBackground: '#191919',
        secondaryBackground: '#303030',
        lightText: '#191919'
    }
}