import React from 'react';
import styled, { css } from 'styled-components';
import cssVariables from './../../cssVariables';


const drawerOpenStyles = css`
    transform: translateX(0px);
`;

const DrawerContainer = styled.div`
    position: fixed;
    background: ${props => props.theme.primaryBackground};
    left: 0px;
    top: 0px;
    bottom: 0px;
    width: ${cssVariables.drawerWidth};
    z-index: 100;
    transition: transform ${cssVariables.drawerToggleSpeed} cubic-bezier(.4, 0, 0, 1);

    &::after {
        content: '';
        display: block;
        position: absolute;
        right: 0px;
        top: 5px;
        bottom: 5px;
        width: 1px;
        opacity: .5;
        background: ${props => props.theme.dividerColor};

        @media (max-width: ${cssVariables.drawerTreshold}) {
            display: none;
        }
    }

    @media (max-width: ${cssVariables.drawerTreshold}) {
        transform: translateX(calc(-100% - 10px))


        ${props => props.open && drawerOpenStyles}
    }
`;

const drawerShadowVisibleStyles = css`
    pointer-events: all;
    opacity: .3;
`;

const DrawerShadow = styled.div`
    display: none

    @media (max-width: ${cssVariables.drawerTreshold}) {
        display: block;
        background: black;
        position: fixed;
        top: 0px;
        left: 0px;
        bottom: 0px;
        right: 0px;
        opacity: 0;
        pointer-events: none;
        z-index: 99;
        transition: opacity ${cssVariables.drawerToggleSpeed} cubic-bezier(.4, 0, 0, 1);
        box-shadow: -2px 0px 10px 0px rgba(0, 0, 0, 0.5);

        ${props => props.visible && drawerShadowVisibleStyles}
    }
`;

const ContentContainer = styled.div`
    margin-left: ${cssVariables.drawerWidth};
    
    @media (max-width: ${cssVariables.drawerTreshold}) {
        margin-left: 0px;
    }
`;

const Drawer = (props) => {

    return (
        <>
            <DrawerContainer open={props.isOpen}>
                {props.drawerJSX}
            </DrawerContainer>
            <DrawerShadow
                visible={props.isOpen}
                onClick={() => props.toggleFunction(false)}
            />
            <ContentContainer>
                {props.children}
            </ContentContainer>
        </>
    )
}

export default Drawer;