const initialState = [];

export default (state = initialState, action) => {
    switch(action.type) {
        case 'like':
            return [...state, action.payload]
        case 'unlike':
            return state.filter(e => e !== action.payload)
        default:
            return state;
    }
}