import React from 'react';
import styled from 'styled-components';
import cssVariables from './../../cssVariables';


import { ReactComponent as MenuSVG } from './menu.svg';

const HeaderDiv = styled.div`
    background: ${props => props.theme.primaryColor};
    height: ${cssVariables.headerHeight};
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0px 10px;
    box-sizing: border-box;
    box-shadow: 0px -1px 10px 0px rgba(0, 0, 0, 0.6);
    position: fixed;
    z-index: 1;
`;


const MenuButton = styled(MenuSVG)`
    height: 30px;
    width: 30px;
    fill: ${props => props.theme.lightText};
    margin-right: 10px;
    cursor: pointer;
    display: none;

    @media (max-width: ${cssVariables.drawerTreshold}) {
        display: initial;
    }
`;

const Title = styled.span`
    color: ${props => props.theme.lightText};
    font-size: 23px;
`;

const Header = ({ toggleDrawer }) => {

    return (
        <HeaderDiv>
            <MenuButton onClick={() => toggleDrawer()}>
                Open Drawer
            </MenuButton>
            <Title>
                Image Finder
            </Title>
        </HeaderDiv>
    );
}

export default Header;