import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { ReactComponent as ExploreIconSVG } from './explore.svg';
import { ReactComponent as FavoritesIconSVG } from './favorites.svg';
import { ReactComponent as SettingsIconSVG } from './settings.svg';


const StyledLink = styled(Link)`
    display: flex;
    align-items: center;
    color: ${props => props.theme.primaryText};
    font-size: 20px;
    text-decoration: none;
    height: 40px;
    padding: 5px 15px;
    cursor: pointer;

    ${props => props.active && css`
        font-weight: bold;
    `}
`;

const iconMixin = css`
    height: 30px;
    fill: ${props => props.theme.primaryText};
    margin-right: 5px;
`;
const ExploreIcon = styled(ExploreIconSVG)`
    ${iconMixin}
`;
const FavoritesIcon = styled(FavoritesIconSVG)`
    ${iconMixin}
`;
const SettingsIcon = styled(SettingsIconSVG)`
    ${iconMixin}
`;


const DrawerContent = ({ closeDrawer, location, match }) => {
    return (
        <>
            <StyledLink
                to="/explore"
                onClick={() => closeDrawer()}
                active={(location.pathname === "/explore")}
            >
                <ExploreIcon />
                Explore
            </StyledLink>
            <StyledLink
                to="/favorite"
                onClick={() => closeDrawer()}
                active={(location.pathname === "/favorite")}
            >
                <FavoritesIcon />
                Favorites
            </StyledLink>
            <StyledLink
                to="/settings"
                onClick={() => closeDrawer()}
                active={(location.pathname === "/settings")}
            >
                <SettingsIcon />
                Settings
            </StyledLink>
        </>
    );
}

export default withRouter(DrawerContent);