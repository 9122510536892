import React, { useState, lazy, Suspense } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom";

import styled from 'styled-components';
import cssVariables from './../../cssVariables';
import themeImport from './../../theme';
import { ThemeProvider } from 'styled-components';

import { connect } from 'react-redux';
import ClimbingBoxLoader from 'react-spinners/ClimbingBoxLoader';
import Drawer from './../Drawer';
import DrawerContent from './../DrawerContent';
import Header from './../Header';

const LazyExplore = lazy(() => import('./../Explore'));
const LazyFavorites = lazy(() => import('./../Favorites'));
const LazySettings = lazy(() => import('./../Settings'));

const LoaderContainer = styled.div`
    width: 100%;
    height: calc(100vh - ${cssVariables.headerHeight});
    display: flex;
    justify-content: center;
    align-items: center;
`;


const App = ({ darkMode }) => {
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);

    const theme = darkMode ? themeImport.dark : themeImport.light;
    const loaderJSX = <LoaderContainer><ClimbingBoxLoader color={theme.primaryColor} size="15" /></LoaderContainer>;

    const Background = styled.div`
        width: 100%;
        min-height: 100vh;
        background: ${props => props.theme.primaryBackground};
    `;

    return (
        <Router>
            <ThemeProvider theme={theme}>
                <Drawer
                    isOpen={isDrawerOpen}
                    toggleFunction={setIsDrawerOpen}
                    drawerJSX={<DrawerContent 
                        closeDrawer={() => setIsDrawerOpen(false)}
                    />}
                >
                    <Background>
                        <Header toggleDrawer={() => setIsDrawerOpen(!isDrawerOpen)} />
                        <div style={{height: cssVariables.headerHeight}}></div>
                        <Switch>
                            <Route path="/explore">
                                <Suspense fallback={loaderJSX}>
                                    <LazyExplore />
                                </Suspense>
                            </Route>
                            <Route path="/favorite">
                                <Suspense fallback={loaderJSX}>
                                    <LazyFavorites />
                                </Suspense>
                            </Route>
                            <Route path="/settings">
                                <Suspense fallback={loaderJSX}>
                                    <LazySettings />
                                </Suspense>
                            </Route>
                        </Switch>
                    </Background>
                </Drawer>
            </ThemeProvider>
        </Router>
    );
}

const mapStateToProps = state => ({
    darkMode: state.settings.darkMode
})

export default connect(mapStateToProps)(App);