const initalState = {
    darkMode: false
}

export default (state = initalState, action) => {
    switch(action.type) {
        case 'TOGGLEDARKMODE':
            return {
                ...state,
                darkMode: action.value
            }
        default:
            return state;
    }
}