import { createStore, compose } from 'redux';
import persistState from 'redux-localstorage'
import rootReducer from './Reducers/rootReducer';

const enhancers = [
    persistState()
];
if('__REDUX_DEVTOOLS_EXTENSION__' in window) {
    enhancers.push(window.__REDUX_DEVTOOLS_EXTENSION__());
}


const configureStore = state => {
    return createStore(rootReducer, state, compose(...enhancers));
}

export default configureStore;